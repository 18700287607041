<template>
  <div class="schedule">
    <!--    <div class="_title">会议日程</div>-->
    <div class="_title">
      <img
        src="../../assets/img/meeting_time.png"
        alt=""
        style="width:100%;height:auto;display:block;"
      />
    </div>

    <!--    <v-timeline class="mt-5">-->
    <!--      <v-timeline-item-->
    <!--        v-for="(item, i) in list"-->
    <!--        :key="i"-->
    <!--        :color="item.color"-->
    <!--        small-->
    <!--      >-->
    <!--        <template v-slot:opposite>-->
    <!--          <span-->
    <!--            :class="`headline2 font-weight-bold ${item.color}&#45;&#45;text`"-->
    <!--            v-text="item.year"-->
    <!--          ></span>-->
    <!--        </template>-->
    <!--        <v-card min-height="50" elevation="2" class="elevation-2 pa-0">-->
    <!--          <v-card-text class="desc pa-2" style="font-weight: 700">-->
    <!--            {{ item.text }}-->
    <!--          </v-card-text>-->
    <!--        </v-card>-->
    <!--      </v-timeline-item>-->
    <!--    </v-timeline>-->
    <div>
      <p style="margin-top: 30px">
        <span style="padding-left: 25px;"></span
        >除基础报告、大会主旨和专题报告外，大会开辟“青年科学家论坛”交流渠道。大会鼓励参会代表提交论文摘要。
      </p>
      <p>
        <span style="padding-left: 25px;font-weight: 700;"
          >青年科学家论坛：</span
        >
        组委会将从40岁以下青年科学家提供的论文摘要中筛选10名青年科学家论坛报告人。大会将根据报告结果评选优秀报告一等奖和二等奖。
      </p>
      <p>
        <span style="padding-left: 25px;font-weight: 700;"
          >青年科学家论坛摘要投稿：</span
        >
        请参会代表在会议网站提交上传。（模板及要求：请见“<a
          href="https://www.molecularbreeding.cn/download"
          >下载中心</a
        >”）请用标准英文撰写摘要，文责自负。报告摘要提交截止时间：
        <span style="color: red">2023年11月30日</span>。
      </p>
      <!--      <p><span style="padding-left: 25px;font-weight: 700;">墙报交流：</span>接受自由投稿和张贴。设立优秀墙报奖。大会鼓励墙报交流，墙报由会务组统一制作，请于<span style="color: red">2022年2月16日</span> 前通过大会网站上传。（提交路径：个人中心>摘要&墙报； 模板及要求：请见“<a href="https://www.molecularbreeding.cn/download">下载中心</a>”。）-->
      <!--      </p>-->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [
        {
          color: 'cyan',
          year: '2023年12月10日全天',
          text: '大会报到时间',
        },
        {
          color: 'green',
          year: '2023年12月10日 18:30-21:30',
          text: '前沿领域基础报告',
        },
        {
          color: 'green',
          year: '2023年12月11日 19:00-22:20',
          text: '青年科学家论坛',
        },
        {
          color: 'pink',
          year: '2023年12月11日 08:30-18:00',
          text: '大会主题报告',
        },
        {
          color: 'blue',
          year: '2023年12月12日 8:30-18:00',
          text: '大会主题报告',
        },
      ],
    };
  },
  mounted() {},
};
</script>

<style lang="less" scoped>
.headline2 {
  font-size: 21px !important;
}
.schedule {
  padding: 0 20px;
  padding-bottom: 150px;

  ._title {
    text-align: center;
    font-size: 25px;
    font-weight: bold;
  }

  .desc {
    font-size: 16px;
    color: #232323;
    text-align: center;
    line-height: 30px;
  }
}
</style>
